import * as React from "react";

import { useState, useEffect, useRef, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, getPatrolBuddyGoSidePanelData, patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager, HUDOperationsUtilityManager, IfNightTime } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDDropDown, HUDToolTip, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { Fade } from "@trap_stevo/legendarybuilderproreact-ui/LegendaryAnimations";

import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import HUDIoTide from "@trap_stevo/iotide-client";

import PatrolBuddySidePanel from "../HUDComponents/PatrolBuddySidePanel.js";

import PatrolCamViewer from "../HUDComponents/PatrolCamViewer.js";

import HUDVideoPlayer from "../HUDComponents/HUDVideoPlayer.js";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";

import { patrolCams } from "../HUDManagers/PatrolBuddyGoHUDPatrolVisionCamUtilityManager.js";

import { useHUDNotification } from "../HUDComponents/HUDNotificationProvider.js";










const patrolVisionIO = new HUDIoTide();

function PatrolBuddyGoPBGViewPanel(patrolBuddyGoPBGViewPanelConfigurationSettings) 
{
     const {
          pbgDisplayName = "",
          pbgViewTitle = "",
          pbgViewActivity = [],
          pbgStreamsSRC = null,
          pbgSignalSRC = "",
          pbgViewSRC = `rtsp://${patrolCams["front-zone-cam"].camIP}:${patrolCams["front-zone-cam"].camPort}/${patrolCams["front-zone-cam"].access}`,
          patrolCamSessionID = "",
          pbgViewCurrentIndex,
          locality,
          serialK,
          user,
          currentPBGViews = [],
          onVideoPauseAction,
          onVideoEndedAction,
          onVideoErrorAction,
          onVideoPlayAction,
          pbgViewControls = false,
          pbgViewPlaying = true,
          pbgViewMuted = false,
          pbgViewVolume = 0
     } = patrolBuddyGoPBGViewPanelConfigurationSettings;
     
     const [currentPatrolCamData, setCurrentPatrolCamData] = useState(patrolCams["front-zone-cam"]);
     
     const [zoneModeActivated, setZoneModeActivated] = useState(currentPatrolCamData.zoneMode);
     
     const [currentPatrolCams, setCurrentPatrolCams] = useState(Object.values(patrolCams));
     
     const [currentVisionSRC, setCurrentVisionSRC] = useState(pbgViewSRC);
     
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState(pbgViewTitle);
     
     const [processIndicatorFontSize, setProcessIndicatorFontSize] = useState("1.169rem");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("rgba(147.69, 147.69, 147.69, 1)");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [nightModeActivated, setNightModeActivated] = useState(IfNightTime());
     
     const [patrolCamStreaming, setPatrolCamStreaming] = useState(false);
     
     const [restrictedAccessed, setRestrictedAccessed] = useState(false);
     
     const [usernameLabelHover, setUsernameLabelHover] = useState(false);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const [backButtonHover, setBackButtonHover] = useState(false);
     
     const { currentNotification, currentNotificationData, setCurrentNotificationData, displayNotification, notificationText, setNotificationText, notificationType, setNotificationType } = useHUDNotification();
     
     const {sessionCache, setSessionCache, addToSessionCache, clearSessionCache} = useHUDSession();
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     const { palette, paletteColor, colorTransparency } = useHUDPalette();
     
     
     
     
     
     const patrolVisionizerRef = useRef(null);
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible);
     
     var patrolBuddyGoSidePanelData = getPatrolBuddyGoSidePanelData(signedUser, "Global View");
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     let currentPatrolBuddyGoSidePanelData = patrolBuddyGoSidePanelData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     
     
     
     
     
     
     
     
     
     
     const handlePatrolCamSwapAction = useCallback((selectedPatrolCam, index) => {
          setCurrentPatrolCamData(selectedPatrolCam);
          
          setCurrentVisionSRC(`rtsp://${selectedPatrolCam.camIP}:${selectedPatrolCam.camPort}/${selectedPatrolCam.access}`);
          
          setZoneModeActivated(selectedPatrolCam.zoneMode);
          
          return;
     }, []);
     
     const handlePatrolCamCenter = () => {
          HUDUniversalHUDUtilityManager.ScrollToCenter(patrolVisionizerRef, true, false);
          
          return;
     };
     
     const handlePatrolCamPan = (direction) => {
          HUDUniversalHUDUtilityManager.ScrollDirection(patrolVisionizerRef, direction, 27, true);
          
          return;
     };
     
     const handleMouseMove = (e) => {
         HUDUniversalHUDUtilityManager.CheckMouseNearEdge(e, sidePanelVisible ? 169.69 : 27.69, "left", patrolVisionizerRef, setSidePanelVisible);
         
         return;
     };
     
     
     
     
     
     
     
     
     
     
     
     const onPatrolCamStreaming = useCallback((status, connected, playingStream) => {
          setPatrolCamStreaming(playingStream.current);
     }, []);
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          if (signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               setRestrictedAccessed(true);
          }
          
          if (signedIn === true)
          {
               setRestrictedAccessed(false);
               
               setProcessIndicatorFontSize("1.169rem");
               
               setProcessIndicatorColor("rgba(147.69, 147.69, 147.69, 1)");
          }
          
          if (restrictedAccessed === true)
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "rgba(147.69, 147.69, 147.69, 1)" ? "#fa0707" : "rgba(147.69, 147.69, 147.69, 1)"));
                    
                    setProcessIndicatorFontSize((previousFont) => (previousFont === "1.969rem" ? "2.169rem" : "1.969rem"));
               }, 699);
               
               const openAuthentication = setInterval(() => {
                    PatrolBuddyGoAuthenticationPanelNavigationActivation(pbgPanelNavigator);
               }, 1699);
               
               return () => {
                    clearInterval(intervalId);
                    
                    clearInterval(openAuthentication);
               }
          }
          
          return () => {};
     }, [signedUser, signedIn, restrictedAccessed, setRestrictedAccessed]);
     
     useEffect(() => {
          if (HUDOperationsUtilityManager.checkOperation("Connecting to Patrol Vision Communications") || !serialK) { return () => {}; }
          
          patrolVisionIO.createIO("Patrol Vision Communications", "https://utilities.patrolbuddygo.com:9269", {}, {}, "", {}, false, false, () => {
               const currentSerialK = "PBG-" + serialK;
               
               patrolVisionIO.joinChannel("Patrol Vision Communications", `${currentSerialK}-cam`, `${signedUser.userID}_${currentSerialK}_website`);
          });
          
          patrolVisionIO.onEvent("Patrol Vision Communications", "current-patrol-cam-position", (currentPatrolCamCoords) => {
               if (!currentPatrolCamCoords || !currentPatrolCamCoords.direction || !zoneModeActivated || currentPatrolCamData.camID !== currentPatrolCamCoords.camID) { return; }
               
               if (currentPatrolCamCoords.direction !== "center")
               {
                    handlePatrolCamPan(currentPatrolCamCoords.direction);
                    
                    return;
               }
               
               handlePatrolCamCenter();
          })
     }, [signedUser, serialK, currentPatrolCamData]);
     
     useEffect(() => {
          const nightModeWatchInterval = setInterval(() => {
               setNightModeActivated(IfNightTime());
          }, 60000);
          
          return () => {
               clearInterval(nightModeWatchInterval);
          };
     }, []);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "hud-scroller"
               
               ref = {patrolVisionizerRef}
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    borderRadius : "0rem",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor),
                    alignItems : "center",
                    overflowY : "hidden",
                    overflowX : "auto"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               {PatrolBuddyGoHUDUtilityManager.ConfirmPatrolBuddyGoControlPanelAccess(signedUser.accessLevel) && signedIn === true && restrictedAccessed === false ? (
               <>
               <PatrolBuddySidePanel
                    currentPatrolBuddyGoSidePanelFooterData = {currentPatrolBuddyGoSidePanelFooterData}
                    
                    currentPatrolBuddyGoSidePanelData = {currentPatrolBuddyGoSidePanelData}
                    
                    setSidePanelVisible = {setSidePanelVisible}
                    
                    sidePanelVisible = {sidePanelVisible}
                    
                    sidePanelRef = {sidePanelRef}
               />
               
               <div
                    style = {{
                         position : "fixed",
                         display : "flex",
                         flexDirection : "row",
                         justifyContent : "flex-start",
                         alignItems : "center",
                         background : "linear-gradient(to bottom, rgba(0, 0, 0, 0.69), transparent)",
                         width : "calc(100%)",
                         flexShrink : 0,
                         zIndex : 69,
                         paddingBottom : "1.69rem",
                         paddingTop : "1rem",
                         top : 0,
                         right : 15,
                         left : 0,
                    }}
               >
                    <HUDToolTip
                         toolTipConfigurationSettings = {{
                              boxShadow : "none",
                              borderRadius : "0.69rem",
                              background : "rgba(0, 0, 0, 0.369)",
                              color : paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor),
                              paddingTop : "2px",
                              marginLeft : "0.269rem"
                         }}
                         position = "right"
                         text = "Back"
                    >
                    <HUDIcon
                         iconConfigurationSettings = {{
                              display : "flex",
                              cursor : "pointer",
                              transition : "margin-left 0.69s ease, background 0.69s ease-in-out",
                              borderRadius : "1rem",
                              background : backButtonHover ? "rgba(15, 15, 15, 1)" : "rgba(0, 0, 0, 0.369)",
                              height : "1.269rem",
                              width : "1.369rem",
                              paddingBottom : "0.269rem",
                              paddingRight : "0.469rem",
                              paddingLeft : "0.469rem",
                              paddingTop : "0.269rem",
                              marginLeft : sidePanelVisible ? "15.269rem" : "1.269rem"
                         }}
                         
                         color = {paletteColor(palette, "PatrolBuddyAccent.color15", patrolBuddyGoLoadingColor)}
                         
                         name = "fa-chevron-left"
                         
                         onMouseLeave = {() => {
                              setBackButtonHover(false);
                              
                              return;
                         }}
                         
                         onMouseEnter = {() => {
                              setBackButtonHover(true);
                              
                              return;
                         }}
                         
                         onClick = {() => {
                              addToSessionCache("CurrentPBGView", {});
                              
                              pbgPanelNavigator("/global-view");
                              
                              return;
                         }}
                    />
                    </HUDToolTip>
                    
                    <Fade direction = "left" duration = {469} >
                    <label
                         style = {{
                              position : "relative",
                              display : "flex",
                              transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out, margin-left 0.269s ease-in-out",
                              background : "rgba(0, 0, 0, 0.369)",
                              borderRadius : "0.69rem",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              fontSize : patrolBuddyGoTitleHover ? "1.269rem" : processIndicatorFontSize,
                              color : patrolBuddyGoTitleHover ? paletteColor(palette, "PatrolBuddyAccent.color15", patrolBuddyGoLoadingColor) : processIndicatorColor,//"rgba(187.69, 187.69, 187.69, 1)"
                              flexShrink : 0,
                              paddingBottom : "0.469rem",
                              paddingRight : "0.569rem",
                              paddingLeft : "0.569rem",
                              paddingTop : "0.469rem",
                              marginLeft : backButtonHover ? "3.69rem" : "0.69rem",
                         }}
                         
                         onMouseLeave = {() => {
                              setPatrolBuddyGoTitleHover(false);
                         }}
                         
                         onMouseEnter = {() => {
                              setPatrolBuddyGoTitleHover(true);
                         }}
                    >
                         <HUDTyper speed = {processIndicatorTypeSpeed} >
                              {`${pbgDisplayName} (${serialK}) : ${locality.county}, ${locality.state}`}
                         </HUDTyper>
                    </label>
                    </Fade>
               </div>
               
               <label
                    style = {{
                         position : "fixed",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         textAlign : "center",
                         fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                         color : usernameLabelHover ? "#A5A5A5" : paletteColor(palette, "PatrolBuddyAccent.color18", patrolBuddyGoLoadingColor),//"rgba(147.69, 147.69, 147.69, 1)"
                         transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "0.569rem",
                         zIndex : "6999",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setUsernameLabelHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setUsernameLabelHover(true);
                    }}
              >
                    <HUDTyper speed = {7} >
                         {signedUser.username}
                    </HUDTyper>
               </label>
               
               <PatrolCamViewer
                    videoContainerConfigurationSettings = {{
                         position : "relative",
                         borderRadius : "0rem",
                    }}
                    
                    viewerLoadingIndicatorConfigurationSettings = {{
                         height : "69px",
                         width : "69px"
                    }}
                    
                    viewerContainerConfigurationSettings = {{
                         position : "relative",
                         height : "100vh",
                    }}
                    
                    messageConfigurationSettings = {{
                         fontSize : "1.69rem"
                    }}
                    
                    videoConfigurationSettings = {{ 
                         borderRadius : "0rem",
                         background : "black",
                         height : "calc(100%)", 
                         width : "calc(100%)",
                         right : 0,
                    }}
                    
                    onPatrolCamStreaming = {onPatrolCamStreaming}
                    
                    nightModeActivated = {nightModeActivated}
                    
                    streaming = {patrolCamStreaming}
                    
                    sessionID = {patrolCamSessionID}
                    
                    videoSRC = {currentVisionSRC}
                    
                    streamsSRC = {pbgStreamsSRC}
                    
                    signalSRC = {pbgSignalSRC}
                    
                    width = {1920}
               >
               </PatrolCamViewer>
               
               <div
                    style = {{
                         position : "fixed",
                         display : "flex",
                         flexDirection : "row",
                         alignItems : "center",
                         justifyContent : "center",
                         transition : "all 0.69s ease",
                         boxShadow : "3px 3px 7.69px -1.69px rgba(0, 0, 0, 0.569)",
                         background : "rgba(0, 0, 0, 0.1269)",
                         borderRadius : "0.69rem",
                         zIndex : 869,
                         padding : "0.469rem",
                         bottom : "1.269rem",
                         left : sidePanelVisible ? "15.269rem" : "1.069rem"
                    }}
               >
               <HUDDropDown
                    dropDownMenuButtonTitleConfigurationSettings = {{
                         display : "flex",
                         fontWeight : "569",
                         fontSize : "1.269rem",
                         color : "#979cbd",
                         marginLeft : "0.869rem"
                    }}
                    dropDownButtonTitleConfigurationSettings = {{
                         display : "flex",
                         fontWeight : "569",
                         fontSize : "1.169rem",
                         color : "#979cbd",
                         marginLeft : "0.869rem"
                    }}
                    dropDownMenuButtonConfigurationSettings = {{
                         borderRadius : "0.69rem",
                         background : "linear-gradient(to top, rgba(27, 27, 27, 0.369), rgba(27, 27, 27, 0.1069) 169%)",
                         zIndex : 27699,
                         paddingRight : "0.769rem",
                         paddingLeft : "0.769rem"
                    }}
                    dropDownContainerConfigurationSettings = {{
                         borderRadius : "0.69rem"
                    }}
                    dropDownListConfigurationSettings = {{
                         background : "linear-gradient(to top, rgba(37, 37, 37, 0.369), rgba(37, 37, 37, 0.1069) 169%)",
                    }}
                    dropDownButtonClickAction = {handlePatrolCamSwapAction}
                    initiallySelected = {currentPatrolCamData.camIndex}
                    menu = {currentPatrolCams}
                    dropDownDirection = "up"
                    openOnHover = {true}
               />
               </div>
               
               {/*<HUDVideoPlayer
                    videoContainerConfigurationSettings = {{ 
                         position : "absolute",
                         background : "black",
                         height : "100vh", 
                         width : "100vw",
                    }}
                    videoConfigurationSettings = {{ 
                         position : "relative",
                         background : "#1a1010", 
                         height : "100%", 
                         width : "100%",
                    }}
                    videoPlaying = {pbgViewPlaying}
                    videoSRC = {pbgViewSRC}
                    controls = {pbgViewControls}
                    volume = {pbgViewVolume}
                    muted = {pbgViewMuted}
                    onVideoPlay = {onVideoPlayAction}
                    onVideoPause = {onVideoPauseAction}
                    onVideoEnded = {onVideoEndedAction}
                    onVideoError = {onVideoErrorAction}
               >
               </HUDVideoPlayer>*/}
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
               </>
               ) : (
                    <>
                         <video 
                              style = {{ 
                                   width : "calc(57.69%)" 
                              }}
                              
                              src = {require("../HUDContent/PatrolBuddyGoSplashScreen.mp4")}
                              
                              playsInline = {true}
                              autoPlay = {true}
                              preload = "auto"
                              play = {true}
                              loop = {true}
                              muted = {true}
                         >
                              
                         </video>
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                                   color : patrolBuddyGoTitleHover ? "#fa0707" : processIndicatorColor,
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "-5rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   Restricted Access
                              </HUDTyper>
                         </label>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyGoPBGViewPanel;