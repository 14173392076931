import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoAuthenticationPanelNavigationActivation, PatrolBuddyGoAccessNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager, Delay } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import PatrolBuddyGoActionButton from "../HUDComponents/PatrolBuddyGoActionButton.js";

import PatrolBuddySidePanel from "../HUDComponents/PatrolBuddySidePanel.js";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";











function PatrolBuddyGoAuthenticationPanel() 
{
     const [patrolBuddyGoActionButtonHover, setPatrolBuddyGoActionButtonHover] = useState(false);
     
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [signingIn, setSigningIn] = useState(false);
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     const { palette, paletteColor, colorTransparency } = useHUDPalette();
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState("Authorized Personnel Only");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [serialKeyInputInFocus, setSerialKeyInputInFocus] = useState(false);
     
     const [usernameInputInFocus, setUsernameInputInFocus] = useState(false);
     
     const [passwordInputInFocus, setPasswordInputInFocus] = useState(false);
     
     const [serialKeyInputValue, setSerialKeyInputValue] = useState("");
     
     const [usernameInputValue, setUsernameInputValue] = useState("");
     
     const [passwordInputValue, setPasswordInputValue] = useState("");
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const serialKeyInputRef = useRef(null);
     
     const usernameInputRef = useRef(null);
     
     const passwordInputRef = useRef(null);
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     
     
     
     
     
     
     
     
     
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, null, setContactUsModalVisible);
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => !actionButton.needsAuthentication || signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     
     
     
     
     
     
     
     
     
     
     const handleSerialKeyInputInFocus = (e) => {
          setSerialKeyInputInFocus(true);
          
          return;
     };
     
     const handleSerialKeyInputBlur = (e) => {
          setSerialKeyInputInFocus(false);
          
          return;
     };
     
     const handleUsernameInputInFocus = (e) => {
          setUsernameInputInFocus(true);
          
          return;
     };
     
     const handleUsernameInputBlur = (e) => {
          setUsernameInputInFocus(false);
          
          return;
     };
     
     const handlePasswordInputInFocus = (e) => {
          setPasswordInputInFocus(true);
          
          return;
     };
     
     const handlePasswordInputBlur = (e) => {
          setPasswordInputInFocus(false);
          
          return;
     };
     
     const handleSerialKeyInputChange = (e) => {
          setSerialKeyInputValue(e.target.value);
          
          return;
     };
     
     const handleUsernameInputChange = (e) => {
          setUsernameInputValue(e.target.value);
          
          return;
     };
     
     const handlePasswordInputChange = (e) => {
          setPasswordInputValue(e.target.value);
          
          return;
     };
     
     const handleMouseMove = (e) => {
          HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 269, e);
          
          return;
     };
     
     const handleEnterKeyAuthenticationActivation = async (e) => {
          if (e.key === "Enter" && usernameInputValue.trim() !== "" && passwordInputValue.trim() !== "" && serialKeyInputValue.trim() !== "")
          {
               setPatrolBuddyGoActionButtonHover(true);
               
               await Delay(469);
               
               await HandleLogin();
               
               return;
          }
          
          return;
     };
     
     async function HandleLogin() 
     {
          setProcessIndicatorTitle("Authorizing...");
          
          setProcessIndicatorTypeSpeed(21);
          
          setSigningIn(true);
          
          const authenticated = await PatrolBuddyGoHUDUtilityManager.AuthenticateUser(serialKeyInputValue, usernameInputValue, passwordInputValue, signedUser, setSignedUser, signedIn, signIn, signOut);
          
          await Delay(869);
          
          setProcessIndicatorTypeSpeed(7);
          
          if (authenticated === true)
          {
               setProcessIndicatorTitle("Authorization Successful!");
          }
          else
          {
               setProcessIndicatorTitle("Try Again...");
          }
          
          setProcessIndicatorColor("#616161");
          
          await Delay(1869);
          
          setPatrolBuddyGoActionButtonHover(false);
          
          setProcessIndicatorTypeSpeed(3);
          
          setSigningIn(false);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          const timer = setTimeout(() => {
               if (usernameInputRef.current === null || usernameInputRef.current === undefined) { return; }
               
               usernameInputRef.current.focus();
               
               return;
          }, 100);
          
          return () => clearTimeout(timer);
     }, []);
     
     useEffect(() => {
          if (signingIn === true) 
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#A5A5A5" : "#616161"));
               }, 569);
               
               return () => clearInterval(intervalId);
          }
          
          if (signedUser !== undefined && PatrolBuddyGoHUDUtilityManager.CheckEmptyData(signedUser) === false)
          {
               PatrolBuddyGoAccessNavigationActivation(pbgPanelNavigator, signedUser);
               
               return () => {};
          }
          
          return () => {};
     }, [signingIn, signedUser]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "gr-scroller"
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto",
                    background : paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor),
                    minHeight : "100vh",
                    minWidth : "100vw",
                    height : "calc(100%)",
                    width : "calc(100%)"
               }}
               
               onKeyPress = {handleEnterKeyAuthenticationActivation}
               
               onMouseMove = {handleMouseMove}
          >
               {!signingIn ? (
                    <PatrolBuddySidePanel
                         currentPatrolBuddyGoSidePanelFooterData = {currentPatrolBuddyGoSidePanelFooterData}
                         
                         setSidePanelVisible = {setSidePanelVisible}
                         
                         sidePanelVisible = {sidePanelVisible}
                         
                         sidePanelRef = {sidePanelRef}
                         
                         containsTopHeader = {true}
                    />
               ) : (
                    <>
                    </>
               )}
               
               <label
                    style = {{
                         position : "relative",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         fontSize : patrolBuddyGoTitleHover ? "3.169rem" : "2.69rem",
                         color : patrolBuddyGoTitleHover ? paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor) : processIndicatorColor,
                         transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "5.469rem",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setPatrolBuddyGoTitleHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setPatrolBuddyGoTitleHover(true);
                    }}
               >
                    <HUDTyper speed = {processIndicatorTypeSpeed} >
                         {processIndicatorTitle}
                    </HUDTyper>
               </label>
               
               <div
                    style = {{
                         position : "relative",
                         height : "0.169rem",
                         width : "calc(87%)",
                         background : paletteColor(palette, "PatrolBuddyAccent.color18", patrolBuddyGoLoadingColor),
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               <form
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         alignItems : "center",
                         transition : "height 0.269s ease-in-out, background 0.869s ease-in-out, box-shadow 0.869s ease-in-out",
                         borderRadius : "1rem",
                         border : signingIn ? "none" : `solid ${paletteColor(palette, "PatrolBuddyAccent.color17", patrolBuddyGoLoadingColor)}`,
                         boxShadow : signingIn ? "none" : "inset 0 3px 7.69px rgba(0, 0, 0, 0.69)",
                         background : signingIn ? "transparent" : paletteColor(palette, "PatrolBuddyAccent.color18", patrolBuddyGoLoadingColor),
                         height : "auto",
                         width : "calc(69%)",
                         marginBottom : "2rem",
                         marginTop : "2.469rem",
                    }}
              >
                    <input
                         className = "verification-modal-input-light"
                         
                         ref = {usernameInputRef}
                         
                         style = {{ 
                              transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in, border-radius 0.269s ease-in",
                              outline : "none",
                              visibility : signingIn ? "hidden" : "visible",
                              textIndent : "0.69rem",
                              fontFamily : "ModeNine",
                              fontSize : "1.269rem",
                              borderRadius : usernameInputInFocus ? "1.269rem" : "1.269rem 1.269rem 1.269rem 0.69rem",
                              borderBottom : usernameInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              borderRight : usernameInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              borderLeft : "none",
                              borderTop : "none",
                              boxShadow : usernameInputInFocus ? `0 0 10px rgba(255, 255, 255, 0.69), inset 0 -1.69px 4.69px ${colorTransparency(paletteColor(palette, "PatrolBuddyLights.color1", patrolBuddyGoLoadingColor), 0.469)}` : `inset 0 -1.69px 4.69px ${colorTransparency(paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor), 0.469)}`,
                              background : usernameInputInFocus ? "transparent" : colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color19", patrolBuddyGoLoadingColor), 0.1069),
                              color : usernameInputInFocus ? "#C9F5FF" : "#A5A5A5",
                              height : "2.969rem",
                              width : "calc(57%)",
                              scale : usernameInputInFocus ? "1.069" : "1",
                              opacity : signingIn ? 0 : 1,
                              marginTop : "3rem"
                         }}
                         
                         value = {usernameInputValue}
                         
                         onChange = {handleUsernameInputChange}
                         
                         onFocus = {handleUsernameInputInFocus}
                         
                         onBlur = {handleUsernameInputBlur}
                         
                         placeholder = "Username..."
                         
                         type = "text"
                    />
                    
                    <input
                         className = "verification-modal-input-light"
                         
                         ref = {passwordInputRef}
                         
                         style = {{ 
                              transition : "scale 0.3s ease-in-out, opacity 0.69s ease-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in, border-radius 0.269s ease-in",
                              outline : "none",
                              visibility : signingIn ? "hidden" : "visible",
                              textIndent : "0.69rem",
                              fontFamily : "ModeNine",
                              fontSize : "1.269rem",
                              borderRadius : passwordInputInFocus ? "1.269rem" : "1.269rem 1.269rem 1.269rem 0.69rem",
                              borderBottom : passwordInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              borderRight : passwordInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              borderLeft : "none",
                              borderTop : "none",
                              boxShadow : passwordInputInFocus ? `0 0 10px rgba(255, 255, 255, 0.69), inset 0 -1.69px 4.69px ${colorTransparency(paletteColor(palette, "PatrolBuddyLights.color1", patrolBuddyGoLoadingColor), 0.469)}` : `inset 0 -1.69px 4.69px ${colorTransparency(paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor), 0.469)}`,
                              background : passwordInputInFocus ? "transparent" : colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color19", patrolBuddyGoLoadingColor), 0.1069),
                              color : passwordInputInFocus ? "#C9F5FF" : "#A5A5A5",
                              height : "2.969rem",
                              width : "calc(57%)",
                              scale : passwordInputInFocus ? "1.069" : "1",
                              opacity : signingIn ? 0 : 1,
                              marginTop : "3rem"
                         }}
                         
                         value = {passwordInputValue}
                         
                         onChange = {handlePasswordInputChange}
                         
                         onFocus = {handlePasswordInputInFocus}
                         
                         onBlur = {handlePasswordInputBlur}
                         
                         placeholder = "Password..."
                         
                         type = "password"
                    />
                    
                    <input
                         className = "verification-modal-input-light"
                         
                         ref = {serialKeyInputRef}
                         
                         style = {{ 
                              transition : "scale 0.3s ease-in-out, opacity 0.69s ease-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in, border-radius 0.269s ease-in",
                              outline : "none",
                              visibility : signingIn ? "hidden" : "visible",
                              textIndent : "0.69rem",
                              fontFamily : "ModeNine",
                              fontSize : "1.269rem",
                              borderRadius : serialKeyInputInFocus ? "1.269rem" : "1.269rem 1.269rem 1.269rem 0.69rem",
                              borderBottom : serialKeyInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              borderRight : serialKeyInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              borderLeft : "none",
                              borderTop : "none",
                              boxShadow : serialKeyInputInFocus ? `0 0 10px rgba(255, 255, 255, 0.69), inset 0 -1.69px 4.69px ${colorTransparency(paletteColor(palette, "PatrolBuddyLights.color1", patrolBuddyGoLoadingColor), 0.469)}` : `inset 0 -1.69px 4.69px ${colorTransparency(paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor), 0.469)}`,
                              background : serialKeyInputInFocus ? "transparent" : colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color19", patrolBuddyGoLoadingColor), 0.1069),
                              color : serialKeyInputInFocus ? "#C9F5FF" : "#A5A5A5",
                              height : "2.969rem",
                              width : "calc(57%)",
                              scale : serialKeyInputInFocus ? "1.069" : "1",
                              opacity : signingIn ? 0 : 1,
                              marginBottom : "5.69rem",
                              marginTop : "3rem"
                         }}
                         
                         value = {serialKeyInputValue}
                         
                         onChange = {handleSerialKeyInputChange}
                         
                         onFocus = {handleSerialKeyInputInFocus}
                         
                         onBlur = {handleSerialKeyInputBlur}
                         
                         placeholder = "Serial Key..."
                         
                         type = "password"
                    />
               </form>
               
               <div
                    style = {{
                         position : "relative",
                         height : "8.69rem",
                         width : "calc(87%)",
                         background : "transparent",
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               {serialKeyInputValue && usernameInputValue && passwordInputValue && (
               <div
                    style = {{
                         position : "fixed",
                         display : "flex",
                         flexDirection : "column",
                         alignItems : "center",
                         height : "8.469rem",
                         width : "calc(100%)",
                         background : "rgba(0, 0, 0, 0.69)",
                         visibility : signingIn ? "hidden" : "visible",
                         borderRadius : "1rem 1rem 0rem 0rem",
                         bottom : 0
                    }}
               >
                    <PatrolBuddyGoActionButton
                         setPatrolBuddyGoActionButtonHover = {setPatrolBuddyGoActionButtonHover}
                         
                         patrolBuddyGoActionButtonHover = {patrolBuddyGoActionButtonHover}
                         
                         patrolBuddyGoActionButtonIcon = {require("../HUDContent/HUDPatrolBuddyGoIcon.png")}
                         
                         patrolBuddyGoActionButtonTitle = "Confirm"
                         
                         HandlePatrolBuddyGoAction = {HandleLogin}
                    />
               </div>
               )}
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
          </div>
     );
}

export default PatrolBuddyGoAuthenticationPanel;