import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, getPatrolBuddyGoSidePanelData, patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager, Delay } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import PatrolBuddyGoActionButton from "../HUDComponents/PatrolBuddyGoActionButton.js";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import PatrolBuddySidePanel from "../HUDComponents/PatrolBuddySidePanel.js";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";











function PatrolBuddyGoProductPanel() 
{
     const [patrolBuddyGoActionButtonHover, setPatrolBuddyGoActionButtonHover] = useState(false);
     
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState("PatrolBuddyGo");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [downloading, setDownloading] = useState(false);
     
     const [usernameLabelHover, setUsernameLabelHover] = useState(false);
     
     const [backButtonHover, setBackButtonHover] = useState(false);
     
     const [currentProductShowcase, setCurrentProductShowcase] = useState(null);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     const { palette, paletteColor, colorTransparency } = useHUDPalette();
     
     
     
     
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     const productDetails = [
          {
               description : "Patrol Buddy Go is an advanced, on-car, camera system that utilizes artificial intelligence and state of the art components to ensure safety, reliability, and all surrounding visual awareness to the user and precinct.",
               productDetailOrientation : 0,
               showcaseMedia : require("../HUDContent/PatrolBuddyGoBodyShowcase.png"),
          },
          {
               description : "Officers can control this system via proprietary desktop application providing flexible, artificially intelligent camera footage, and more.",
               productDetailOrientation : 1,
               showcaseMedia : require("../HUDContent/PBGDashboardShowcase.png"),
          }
     ];
     
     const productShowcases = [
          {
               description : "Sits on top of a lightbar!",
               showcaseMedia : require("../HUDContent/PatrolBuddyGoOnLightbar.jpg"),
          },
          {
               description : "Sits on top of a lightbar!",
               showcaseMedia : require("../HUDContent/PatrolBuddyGoOnLightbarFrontView.jpg"),
          },
          {
               description : "Sits on top of a lightbar!",
               showcaseMedia : require("../HUDContent/PatrolBuddyGoOnLightbarSideView.jpg"),
          }
     ];
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible);
     
     var patrolBuddyGoSidePanelData = getPatrolBuddyGoSidePanelData(signedUser, "PatrolBuddyGo");
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => !actionButton.needsAuthentication || signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     let currentPatrolBuddyGoSidePanelData = patrolBuddyGoSidePanelData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     
     
     
     
     
     
     
     
     
     
     async function HandlePBGDownload()
     {
          setDownloading(true);
          
          setProcessIndicatorTypeSpeed(21);
          
          setProcessIndicatorTitle("Downloading...");
          
          const serialKeyVerification = await PatrolBuddyGoHUDUtilityManager.VerifySerialKey(signedUser.serialKey);
          
          if (serialKeyVerification === true) 
          {
               const downloadedPatrolBuddyGo = await PatrolBuddyGoHUDUtilityManager.DownloadPatrolBuddyGo(signedUser.serialKey);
               
               setTimeout(() => {
                    if (downloadedPatrolBuddyGo === true)
                    {
                         setProcessIndicatorTitle("Download Successful!");
                    }
                    else
                    {
                         setProcessIndicatorTitle("Try Again...");
                    }
                    
                    setProcessIndicatorColor("#616161");
                    
                    setDownloading(false);
               }, 3000);
               
               setTimeout(() => {
                    setProcessIndicatorTitle("PatrolBuddyGo");
                    
                    setProcessIndicatorTypeSpeed(3);
               }, 5000);
               
               return;
           }
           
           setProcessIndicatorTitle("Try Again...");
           
           setProcessIndicatorColor("#616161");
           
           setDownloading(false);
           
           setTimeout(() => {
               setProcessIndicatorTitle("PatrolBuddyGo");
               
               setProcessIndicatorTypeSpeed(3);
           }, 5000);
           
           return;
     };
     
     const handlePatrolBuddyGoAction = async (navigateTo) => {
           setPatrolBuddyGoActionButtonHover(true);
           
           if (signedIn === false)
           {
                await Delay(369);
                
                setPatrolBuddyGoActionButtonHover(false);
                
                navigateTo("/authentication");
                
                return;
           }
           
           HandlePBGDownload();
           
           return;
     };
     
     const handleMouseMove = (e) => {
           HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 269, e);
           
           return;
     };
     
     const handleEnterKeyActivation = async (e) => {
          if (e.key === "Enter")
          {
               await handlePatrolBuddyGoAction(pbgPanelNavigator);
               
               return;
          }
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          if (downloading === true) 
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#A5A5A5" : "#616161"));
               }, 569);
               
               return () => clearInterval(intervalId);
          }
          
          return;
     }, [downloading]);
     
     useEffect(() => {
          window.addEventListener("keydown", handleEnterKeyActivation);
          
          return () => {
               window.removeEventListener("keydown", handleEnterKeyActivation);
          };
     }, [signedIn]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "gr-scroller"
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto",
                    background : paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor),
                    minHeight : "100vh",
                    minWidth : "100vw",
                    height : "calc(100%)",
                    width : "calc(100%)"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               <PatrolBuddySidePanel
                    currentPatrolBuddyGoSidePanelFooterData = {currentPatrolBuddyGoSidePanelFooterData}
                    
                    currentPatrolBuddyGoSidePanelData = {currentPatrolBuddyGoSidePanelData}
                    
                    setSidePanelVisible = {setSidePanelVisible}
                    
                    sidePanelVisible = {sidePanelVisible}
                    
                    sidePanelRef = {sidePanelRef}
               />
               
               <label
                    style = {{
                         position : "relative",
                         flexShrink : 0,
                         transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         fontSize : patrolBuddyGoTitleHover ? "3.169rem" : "2.69rem",
                         color : patrolBuddyGoTitleHover ? paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor) : processIndicatorColor,
                         marginTop : "5.469rem"
                    }}
                    
                    onMouseLeave = {() => {
                         setPatrolBuddyGoTitleHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setPatrolBuddyGoTitleHover(true);
                    }}
               >
                    <HUDTyper speed = {processIndicatorTypeSpeed} >
                         {processIndicatorTitle}
                    </HUDTyper>
               </label>
               
               <div
                    style = {{
                         position : "relative",
                         height : "0.169rem",
                         width : "calc(87%)",
                         background : paletteColor(palette, "PatrolBuddyAccent.color18", patrolBuddyGoLoadingColor),
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               {productDetails.map((productDetailsData, index) => (
                    productDetailsData.productDetailOrientation === 1 ? (
                         <div
                              key = {index}
                              
                              style = {{
                                   position : "relative",
                                   display : "flex",
                                   flexDirection : "row",
                                   justifyContent : "center",
                                   height : "24rem",
                                   width : "calc(87%)",
                                   borderRadius : "1rem",
                                   background : paletteColor(palette, "PatrolBuddyAccent.color18", patrolBuddyGoLoadingColor),
                                   marginBottom : "2rem",
                                   marginTop : "2.469rem",
                                   overflow : "hidden",
                              }}
                         >
                              <img
                                   style = {{
                                        background : "transparent",
                                        height : "calc(90%)",
                                        width : "calc(50%)",
                                        borderRadius : "1rem",
                                        marginRight : "2.69rem",
                                        marginTop : "1.269rem",
                                   }}
                                   
                                   src = {productDetailsData.showcaseMedia}
                              />
                              
                              <label
                                   style = {{
                                        position : "relative",
                                        fontFamily : "ModeNine",
                                        fontWeight : "bold",
                                        fontSize : "1.469rem",
                                        width : "calc(37%)",
                                        color : paletteColor(palette, "PatrolBuddyAccent.color14", patrolBuddyGoLoadingColor),
                                        marginTop : "3.469rem",
                                        flexShrink : 0
                                   }}
                              >
                                   <HUDTyper speed = {2} >
                                        {productDetailsData.description}
                                   </HUDTyper>
                              </label>
                         </div>
                    ) : (
                         <div
                              key = {index}
                              
                              style = {{
                                   position : "relative",
                                   display : "flex",
                                   flexDirection : "row",
                                   justifyContent : "center",
                                   height : "24rem",
                                   width : "calc(87%)",
                                   borderRadius : "1rem",
                                   background : paletteColor(palette, "PatrolBuddyAccent.color18", patrolBuddyGoLoadingColor),
                                   marginBottom : "2rem",
                                   marginTop : "2.469rem",
                                   overflow : "hidden"
                              }}
                         >
                              <label
                                   style = {{
                                        position : "relative",
                                        fontFamily : "ModeNine",
                                        fontWeight : "bold",
                                        fontSize : "1.469rem",
                                        width : "calc(37%)",
                                        color : paletteColor(palette, "PatrolBuddyAccent.color14", patrolBuddyGoLoadingColor),
                                        marginRight : "2.69rem",
                                        marginTop : "3.469rem",
                                        flexShrink : 0
                                   }}
                              >
                                   <HUDTyper speed = {2} >
                                        {productDetailsData.description}
                                   </HUDTyper>
                              </label>
                              
                              <img
                                   style = {{
                                        background : "transparent",
                                        height : "calc(90%)",
                                        width : "calc(50%)",
                                        borderRadius : "1rem",
                                        marginTop : "1.269rem",
                                   }}
                                   
                                   src = {productDetailsData.showcaseMedia}
                              />
                         </div>
                    ))
               )}
               
               <div
                    style = {{
                         position : "relative",
                         height : "0.469rem",
                         width : "calc(87%)",
                         background : paletteColor(palette, "PatrolBuddyAccent.color18", patrolBuddyGoLoadingColor),
                         marginBottom : "5rem",
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               {productShowcases.map((productShowcaseData, index) => (
               <div
                    key = {index}
                    
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "row",
                         justifyContent : "center",
                         height : "24rem",
                         width : "calc(87%)",
                         borderRadius : "1rem",
                         background : paletteColor(palette, "PatrolBuddyAccent.color18", patrolBuddyGoLoadingColor),
                         marginBottom : "2rem",
                         marginTop : "2.469rem",
                    }}
               >
                    <img
                         style = {{
                              background : "transparent",
                              height : "calc(90%)",
                              width : "calc(30%)",
                              scale : currentProductShowcase === index ? "1.269" : "1",
                              transition : "scale 0.3s ease-in-out", 
                              boxShadow : "0 0 20px rgba(0, 0, 0, 0.69)",
                              borderRadius : "1rem",
                              marginLeft : "1rem",
                              marginTop : "1.269rem",
                         }}
                         
                         src = {productShowcaseData.showcaseMedia}
                         
                         onMouseLeave = {() => {
                              setCurrentProductShowcase(null);
                         }}
                         
                         onMouseEnter = {() => {
                              setCurrentProductShowcase(index);
                         }}
                    />
               </div>
               ))}
               
               {signedIn && (
               <label
                    style = {{
                         position : "fixed",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         textAlign : "center",
                         fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                         color : usernameLabelHover ? "#A5A5A5" : "#616161",
                         transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "0.569rem",
                         zIndex : "6999",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setUsernameLabelHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setUsernameLabelHover(true);
                    }}
              >
                    <HUDTyper speed = {7} >
                         {signedUser.username}
                    </HUDTyper>
               </label>
               )}
               
               <div
                    style = {{
                         position : "relative",
                         height : "15rem",
                         width : "calc(87%)",
                         background : "transparent",
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               <div
                    style = {{
                         position : "fixed",
                         display : "flex",
                         flexDirection : "column",
                         alignItems : "center",
                         height : "8.469rem",
                         width : "calc(100%)",
                         background : "rgba(0, 0, 0, 0.69)",
                         visibility : downloading ? "hidden" : "visible",
                         borderRadius : "1rem 1rem 0rem 0rem",
                         bottom : 0
                    }}
               >
                    <PatrolBuddyGoActionButton
                         setPatrolBuddyGoActionButtonHover = {setPatrolBuddyGoActionButtonHover}
                         
                         patrolBuddyGoActionButtonHover = {patrolBuddyGoActionButtonHover}
                         
                         patrolBuddyGoActionButtonIcon = {require("../HUDContent/HUDPatrolBuddyGoIcon.png")}
                         
                         patrolBuddyGoActionButtonTitle = {signedIn ? "Download" : "Login"}
                         
                         HandlePatrolBuddyGoAction = {handlePatrolBuddyGoAction}
                    />
               </div>
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
          </div>
     );
}

export default PatrolBuddyGoProductPanel;