import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, {PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation, PatrolBuddyGoAccountCreationPanelNavigationActivation} from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, getPatrolBuddyGoSidePanelData, patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager, HUDOperationsUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDWasabiService, useMonitorFileActivity } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDWindow } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDVault } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDEventManager } from "@trap_stevo/legendarybuilderproreact-ui";

import PatrolBuddyDataDashboardView from "../HUDViews/PatrolBuddyDataDashboardView.js";

import PatrolBuddySidePanel from "../HUDComponents/PatrolBuddySidePanel.js";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";

import PBGViewCell from "../HUDComponents/PBGViewCell.js";











function PatrolBuddyGoPatrolDataHubPanel() 
{
     const [patrolBuddyGoActionButtonHover, setPatrolBuddyGoActionButtonHover] = useState(false);
     
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState("Patrol Data Hub");
     
     const [processIndicatorFontSize, setProcessIndicatorFontSize] = useState("1.969rem");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [restrictedAccessed, setRestrictedAccessed] = useState(false);
     
     const [usernameLabelHover, setUsernameLabelHover] = useState(false);
     
     const [sidePanelLogoHover, setSidePanelLogoHover] = useState(false);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const [selectedFile, setSelectedFile] = useState(false);
     
     const [files, setFiles] = useState([]);
     
     const {sessionCache, setSessionCache, addToSessionCache, clearSessionCache} = useHUDSession();
     
     const { fileCache, loadFiles, loadFilesWithCache, getFileURL } = useHUDWasabiService();
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     const { palette, paletteColor, colorTransparency } = useHUDPalette();
     
     const { windowContentsRef, windowContents, addWindowContents } = useHUDWindow();
     
     const { credentials } = useHUDVault();
     
     
     
     
     
     const clearPatrolEvidenceListenerRef = useRef(null);
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible);
     
     var patrolBuddyGoSidePanelData = getPatrolBuddyGoSidePanelData(signedUser, "Patrol Data Hub");
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     let currentPatrolBuddyGoSidePanelData = patrolBuddyGoSidePanelData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     const startMonitoring = useMonitorFileActivity(loadFiles, fileCache, "pbgdata");
     
     
     
     
     
     
     
     
     
     
     
     const monitorPatrolBuddyEvidenceData = () => {
          clearPatrolEvidenceListenerRef.current = startMonitoring("pbgdata", signedUser.locality && signedUser.locality.county === "Universal" ? `GRTEST` : `${signedUser.locality.county.toLowerCase()}`, 30000, async (incomingFiles) => {
               const { newFiles, deletedFiles } = incomingFiles;
               
               var currentFilesList = files;
               
               for (let newLoadedFile of newFiles)
               {
                    if (newLoadedFile.Size > 0)
                    {
                         const fileData = await getFileURL("pbgdata", newLoadedFile.Key);
                         
                         currentFilesList.push(fileData);
                    }
               }
               
               currentFilesList = currentFilesList.filter((file) => !deletedFiles.includes(file));
               
               setFiles(currentFilesList);
          });
     };
     
     const gatherPatrolBuddyEvidence = async () => {
          var currentFiles = [];
          
          const loadedFiles = await loadFilesWithCache("pbgdata", signedUser.locality && signedUser.locality.county === "Universal" ? `GRTEST` : `${signedUser.locality.county.toLowerCase()}`);
          
          for (let loadedFile of loadedFiles)
          {
               if (loadedFile.Size > 0)
               {
                    const currentFileData = await getFileURL("pbgdata", loadedFile.Key);
                    
                    currentFiles.push(currentFileData);
               }
          }
          
          setFiles([...currentFiles]);
     };
     
     const viewFile = (file) => {
          setSelectedFile(file);
          
          return;
     };
     
     const handleMouseMove = (e) => {
          HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 27.69, e);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          if (HUDUniversalHUDUtilityManager.CheckEmptyData(signedUser) === true || signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               return;
          }
          
          if (HUDUniversalHUDUtilityManager.CheckEmptyData(credentials) === false && credentials.HUDWasabi) 
          {
               gatherPatrolBuddyEvidence();
               
               monitorPatrolBuddyEvidenceData();
          }
          
          HUDEventManager.on("WCloudStorageOperational", async () => {
               if (HUDOperationsUtilityManager.checkOperation("loadingCloudStoredFiles")) { return; }
               
               gatherPatrolBuddyEvidence();
               
               monitorPatrolBuddyEvidenceData();
          });
          
          return () => {
               if (clearPatrolEvidenceListenerRef.current)
               {
                    clearPatrolEvidenceListenerRef.current();
               }
          };
     }, [signedIn, signedUser]);
     
     useEffect(() => {
          if (signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               setRestrictedAccessed(true);
          }
          
          if (signedIn === true)
          {
               setRestrictedAccessed(false);
               
               setProcessIndicatorFontSize("1.969rem");
               
               setProcessIndicatorColor("#616161");
          }
          
          if (restrictedAccessed === true)
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#fa0707" : "#616161"));
                    
                    setProcessIndicatorFontSize((previousFont) => (previousFont === "1.969rem" ? "2.169rem" : "1.969rem"));
               }, 699);
               
               const openAuthentication = setInterval(() => {
                    PatrolBuddyGoAuthenticationPanelNavigationActivation(pbgPanelNavigator);
               }, 1699);
               
               return () => {
                    clearInterval(intervalId);
                    
                    clearInterval(openAuthentication);
               }
          }
          
          return;
     }, [signedUser, signedIn, restrictedAccessed, setRestrictedAccessed]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "gr-scroller"
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor),
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               {PatrolBuddyGoHUDUtilityManager.ConfirmPatrolBuddyGoControlPanelAccess(signedUser.accessLevel) && signedIn === true && restrictedAccessed === false ? (
               <>
               <PatrolBuddySidePanel
                    currentPatrolBuddyGoSidePanelFooterData = {currentPatrolBuddyGoSidePanelFooterData}
                    
                    currentPatrolBuddyGoSidePanelData = {currentPatrolBuddyGoSidePanelData}
                    
                    setSidePanelVisible = {setSidePanelVisible}
                    
                    sidePanelVisible = {sidePanelVisible}
                    
                    sidePanelRef = {sidePanelRef}
               >
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              fontSize : patrolBuddyGoTitleHover ? "1.269rem" : "1.069rem",
                              color : patrolBuddyGoTitleHover ? "#A1A1A1" : processIndicatorColor,
                              marginTop : "95.69vh"
                         }}
                         
                         onMouseLeave = {() => {
                              setPatrolBuddyGoTitleHover(false);
                         }}
                         
                         onMouseEnter = {() => {
                              setPatrolBuddyGoTitleHover(true);
                         }}
                    >
                         <HUDTyper speed = {processIndicatorTypeSpeed} >
                              {processIndicatorTitle}
                         </HUDTyper>
                    </label>
               </PatrolBuddySidePanel>
               
               <label
                    style = {{
                         position : "fixed",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         textAlign : "center",
                         fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                         color : usernameLabelHover ? "#A5A5A5" : "#616161",
                         transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "0.569rem",
                         zIndex : "6999",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setUsernameLabelHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setUsernameLabelHover(true);
                    }}
              >
                    <HUDTyper speed = {7} >
                         {signedUser.username}
                    </HUDTyper>
               </label>
               
               <PatrolBuddyDataDashboardView
                    colorTransparency = {colorTransparency}
                    
                    paletteColor = {paletteColor}
                    
                    palette = {palette}
                    
                    addWindowContents = {addWindowContents}
                    
                    sidePanelVisible = {sidePanelVisible}
                    
                    setSelectedFile = {setSelectedFile}
                    
                    windowFiles = {windowContents}
                    
                    selectedFile = {selectedFile}
                    
                    viewFile = {viewFile}
                    
                    setFiles = {setFiles}
                    
                    files = {files}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               />
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
               </>
               ) : (
                    <>
                         <video 
                              style = {{ 
                                   width : "calc(57.69%)" 
                              }}
                              
                              src = {require("../HUDContent/PatrolBuddyGoSplashScreen.mp4")}
                              
                              playsInline = {true}
                              autoPlay = {true}
                              preload = "auto"
                              play = {true}
                              loop = {true}
                              muted = {true}
                         >
                              
                         </video>
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                                   color : patrolBuddyGoTitleHover ? "#fa0707" : processIndicatorColor,
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "-5rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   Restricted Access
                              </HUDTyper>
                         </label>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyGoPatrolDataHubPanel;