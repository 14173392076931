import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation, PatrolBuddyGoControlPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, getPatrolBuddyGoSidePanelData, patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { Fade } from "@trap_stevo/legendarybuilderproreact-ui/LegendaryAnimations";

import { HUDSidePanel, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDTracker } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import PatrolBuddyGoActionButton from "../HUDComponents/PatrolBuddyGoActionButton.js";

import { HUDLayoutManagerContainer, HUDLayoutManagerHeader, HUDLayoutManagerBody, HUDLayoutManagerScreen } from "../HUDComponents/HUDLayoutManager.js";

import PatrolBuddyBaseAccountCreationView from "../HUDViews/PatrolBuddyBaseAccountCreationView.js";

import PatrolBuddyAccountCreationView from "../HUDViews/PatrolBuddyAccountCreationView.js";

import PatrolBuddySidePanel from "../HUDComponents/PatrolBuddySidePanel.js";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";











function PatrolBuddyGoAccountCreationPanel() 
{
     const [patrolBuddyGoActionButtonHover, setPatrolBuddyGoActionButtonHover] = useState(false);
     
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [restrictedAccessed, setRestrictedAccessed] = useState(false);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const [processing, setProcessing] = useState(false);
     
     const { addToSessionCache, getFromSessionCache, clearSessionCache } = useHUDSession();
     
     const { trackSessionData, getFromSessionTracker } = useHUDTracker();
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     const { palette, paletteColor, colorTransparency } = useHUDPalette();
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState("Patroller Creation");
     
     const [processIndicatorFontSize, setProcessIndicatorFontSize] = useState("1.969rem");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [usernameLabelHover, setUsernameLabelHover] = useState(false);
     
     
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     
     
     
     
     
     
     
     
     
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible);
     
     var patrolBuddyGoSidePanelData = getPatrolBuddyGoSidePanelData(signedUser, "Add Patrollers");
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => !actionButton.needsAuthentication || signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     let currentPatrolBuddyGoSidePanelData = patrolBuddyGoSidePanelData.filter(actionButton => !actionButton.needsAuthentication || signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     
     
     
     
     
     
     
     
     
     
     const handleMouseMove = (e) => {
          HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 27.69, e);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          if (processing === true) 
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#A5A5A5" : "#616161"));
               }, 569);
               
               return () => clearInterval(intervalId);
          }
          
          return;
     }, [processing]);
     
     useEffect(() => {
          if (signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               setRestrictedAccessed(true);
          }
          
          if (signedIn === true)
          {
               setRestrictedAccessed(false);
               
               setProcessIndicatorFontSize("1.969rem");
               
               setProcessIndicatorColor("#616161");
          }
          
          if (restrictedAccessed === true)
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#fa0707" : "#616161"));
                    
                    setProcessIndicatorFontSize((previousFont) => (previousFont === "1.969rem" ? "2.169rem" : "1.969rem"));
               }, 699);
               
               const openAuthentication = setInterval(() => {
                    PatrolBuddyGoAuthenticationPanelNavigationActivation(pbgPanelNavigator);
               }, 1699);
               
               return () => {
                    clearInterval(intervalId);
                    
                    clearInterval(openAuthentication);
               }
          }
          
          return;
     }, [signedUser, signedIn, restrictedAccessed, setRestrictedAccessed]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "gr-scroller"
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor),
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               {PatrolBuddyGoHUDUtilityManager.ConfirmPatrolBuddyGoControlPanelAccess(signedUser.accessLevel) && signedIn === true && restrictedAccessed === false ? (
                    <>
                         <PatrolBuddySidePanel
                              currentPatrolBuddyGoSidePanelFooterData = {currentPatrolBuddyGoSidePanelFooterData}
                              
                              currentPatrolBuddyGoSidePanelData = {currentPatrolBuddyGoSidePanelData}
                              
                              setSidePanelVisible = {setSidePanelVisible}
                              
                              sidePanelVisible = {sidePanelVisible}
                              
                              sidePanelRef = {sidePanelRef}
                         >
                              <label
                                   style = {{
                                        position : "relative",
                                        flexShrink : 0,
                                        transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                                        fontFamily : "ModeNine",
                                        fontWeight : "bolder",
                                        fontSize : patrolBuddyGoTitleHover ? "1.269rem" : "1.069rem",
                                        color : patrolBuddyGoTitleHover ? "#A1A1A1" : processIndicatorColor,
                                        marginTop : "95.69vh"
                                   }}
                                   
                                   onMouseLeave = {() => {
                                        setPatrolBuddyGoTitleHover(false);
                                   }}
                                   
                                   onMouseEnter = {() => {
                                        setPatrolBuddyGoTitleHover(true);
                                   }}
                              >
                                   <HUDTyper speed = {processIndicatorTypeSpeed} >
                                        {processIndicatorTitle}
                                   </HUDTyper>
                              </label>
                         </PatrolBuddySidePanel>
                         
                         <HUDLayoutManagerContainer
                              containerConfigurationSettings = {{
                                   position : "relative",
                                   background : "transparent",
                                   height : "calc(100%)",
                                   width : "calc(100%)"
                              }}
                         >
                              <HUDLayoutManagerHeader headerConfigurationSettings = {{ flexDirection : "column", transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor), borderBottom : "none" }}>
                                   
                              </HUDLayoutManagerHeader>
                              
                              <HUDLayoutManagerBody 
                                   className = "invisa-scroller"
                                   
                                   screens = {signedUser && signedUser.accessLevel && signedUser.accessLevel.accountAccessR >= 4 ? [{ height : 100, width : 45 }, { height : 100, width : 50 }] : [{ height : 100, width : 100 }]} 
                                   
                                   bodyConfigurationSettings = {{ boxShadow : "0 0 3.69px rgba(201, 245, 255, 0.269)", background : "rgba(37, 37, 37, 1)", height : "77.69vh" }} 
                              >
                                   {signedUser && signedUser.accessLevel && signedUser.accessLevel.accountAccessR >= 4 ? (
                                        <Fade duration = {869} >
                                             <PatrolBuddyBaseAccountCreationView
                                                  
                                             />
                                        </Fade>
                                   ) : (
                                        <>
                                        </>
                                   )}
                                   
                                   <Fade duration = {869} >
                                        <PatrolBuddyAccountCreationView
                                             signedUser = {signedUser}
                                             
                                             signedIn = {signedIn}
                                        />
                                   </Fade>
                              </HUDLayoutManagerBody>
                         </HUDLayoutManagerContainer>
                         
                         
                         
                         <label
                              style = {{
                                   position : "fixed",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                                   color : usernameLabelHover ? "#A5A5A5" : "#616161",
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "0.569rem",
                                   zIndex : "6999",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setUsernameLabelHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setUsernameLabelHover(true);
                              }}
                         >          
                              <HUDTyper speed = {7} >
                                   {signedUser.username}
                              </HUDTyper>
                         </label>
                         
                         <div
                              style = {{
                                   position : "relative",
                                   height : "8.69rem",
                                   width : "calc(87%)",
                                   background : "transparent",
                                   marginTop : "1.469rem",
                              }}
                         >
                              
                         </div>
                         
                         <ContactUsModal
                              contactUsModalVisibilityHandler = {setContactUsModalVisible}
                              
                              modalVisibility = {contactUsModalVisible}
                              
                              signedUser = {signedUser}
                              
                              signedIn = {signedIn}
                         >
                              
                         </ContactUsModal>
                    </>
               ) : (
                    <>
                         <video 
                              style = {{ 
                                   width : "calc(57.69%)" 
                              }}
                              
                              src = {require("../HUDContent/PatrolBuddyGoSplashScreen.mp4")}
                              
                              playsInline = {true}
                              autoPlay = {true}
                              preload = "auto"
                              play = {true}
                              loop = {true}
                              muted = {true}
                         >
                              
                         </video>
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                                   color : patrolBuddyGoTitleHover ? "#fa0707" : processIndicatorColor,
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "-5rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   Restricted Access
                              </HUDTyper>
                         </label>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyGoAccountCreationPanel;